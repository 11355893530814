<template>
  <section class="dtc-main-section mt-4" id="dtc-print-it2">
    <div class="dtc-title pt-2">
      <input />學校 <input />學年度第
      <input />學期「原住民及離島地區醫事人員養成計畫」
      公費生待遇補助返鄉旅費申請單
    </div>
    <div class="mt-2 dtc-form4-input">
      <b-input-group prepend="金額">
        <b-textarea no-resize style="height:100%;"></b-textarea>
      </b-input-group>

      <b-input-group
        prepend="預算科目"
        style="grid-column: 2; grid-row: 1 span 2"
      >
        <b-textarea no-resize style="height: 80px;"></b-textarea>
      </b-input-group>
      <b-input-group prepend="會計編號">
        <b-textarea no-resize style="height:100%;"></b-textarea>
      </b-input-group>
      <b-input-group prepend="學生姓名">
        <b-textarea no-resize style="height:100%;"></b-textarea>
      </b-input-group>
      <b-input-group prepend="請購編號" style="grid-column: 3;">
        <b-textarea no-resize style="height:100%;"></b-textarea>
      </b-input-group>
    </div>

    <div class="mt-2 dtc-form4-input">
      <b-input-group prepend="學系/年級">
        <b-textarea no-resize style="height:100%;"></b-textarea>
      </b-input-group>
      <b-input-group prepend="居家地址" style="grid-column: 2 / -1;">
        <b-textarea no-resize style="height:100%;"></b-textarea>
      </b-input-group>
      <b-input-group prepend="出差事由" style="grid-column: 1 / -1;">
        <b-textarea no-resize style="height:100%;"></b-textarea>
      </b-input-group>
      <main
        style="grid-column: 1 / -1; justify-self:center;"
        class="pt-2 pb-2 dx-main"
      >
        中華民國
        <input class="text-center" />年 <input class="text-center" /> 月
        <input class="text-center" /> 日起止共計
        <input class="text-center" /> 日附單據 <input class="text-center" /> 張
      </main>
    </div>
    <div class="dtc-date2-grid">
      <section class="dtc-date4-grid">
        <div class="pt-2">月</div>
      </section>
      <div class="dtc-word" style="padding-top:50px;">核支標準說明</div>
    </div>
    <section class="dtc-v10-grid">
      <div style="grid-row:1/-1;border:none;">
        <div class="pl-1" style="padding-top:69px">交通費</div>
        <div class="dtc-sec">
          <div>{{ v[0] }}</div>
          <div>{{ v[1] }}</div>
          <div>{{ v[2] }}</div>
          <div>{{ v[3] }}</div>
          <div style="border-bottom:none;">{{ v[4] }}</div>
        </div>
      </div>
    </section>
    <div class="dtc-date2-grid">
      <section class="dtc-date4-grid">
        <div class="pt-2" style="border-bottom:none;">總 計</div>
        <div style="border-bottom:none;">{{ total1 }}</div>
        <div style="border-bottom:none;">{{ total2 }}</div>
        <div style="border-bottom:none;">{{ total3 }}</div>
        <div style="border-bottom:none;">{{ total4 }}</div>
      </section>
      <div>
        <b-input
          style="transform:translateX(-25px);width:124px;height:45px;border:none;"
        ></b-input>
      </div>
    </div>
    <nav class="dtc-note2">
      <div class="pl-2 pt-2 pb-5">
        備註
        <br />
        <span style="color:red;">(非按返鄉必經之順路者，應備註說明緣由)</span>
        <br />
      </div>
    </nav>
    <footer class="dtc-f1 pl-2 p2-4 dx-main">
      <div>上列出差旅費計新台幣</div>
      <b-input v-model="getW" />
      <div>萬</div>
      <b-input v-model="getG" />
      <div>仟</div>
      <b-input v-model="get100" />
      <div>佰</div>
      <b-input v-model="get10" />
      <div>拾</div>
      <b-input v-model="get1" />
      <div>元整，具領人</div>
      <b-input />
      <div>簽名</div>
    </footer>
    <footer class="dtc-f2 mt-4">
      <div>
        【請於背面依先後次序貼妥票根，並計總票價核對無誤；
        <br />另倘有溢出之金額，如補票價者或非直接行程者，恕不核給】
      </div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
let m1 = "",
  m2 = "",
  m3 = "",
  m4 = "",
  d1 = "",
  d2 = "",
  d3 = "",
  d4 = "",
  s1 = "",
  s2 = "",
  s3 = "",
  s4 = "",
  p9 = "",
  t9 = "",
  c9 = "",
  b9 = "",
  o9 = "",
  tt = "";
const map = {};

new Array(8).fill().forEach((s, i) => {
  map["plan" + i] = "";
  map["mrt" + i] = "";
  map["train" + i] = "";
  map["board" + i] = "";
  map["other" + i] = "";
});

const v = ["飛機及高鐵", "汽車及捷運", "火車", "輪船", "其他"];
export default {
  name: "doc2",
  data() {
    return {
      m1,
      m2,
      m3,
      m4,
      d1,
      d2,
      d3,
      d4,
      s1,
      s2,
      s3,
      s4,
      v,
      p9,
      t9,
      c9,
      b9,
      o9,
      tt,
      map,
    };
  },

  computed: {
    total1() {
      let n = 0;
      try {
        n =
          +this.map.plan0 +
          +this.map.plan1 +
          +this.map.mrt0 +
          +this.map.mrt1 +
          +this.map.train0 +
          +this.map.train1 +
          +this.map.board0 +
          +this.map.board1;
        n += +this.map.other0 + +this.map.other1;
        n = Number(n);
      } catch {
        return "";
      }
      return n;
    },
    total2() {
      let n = 0;
      try {
        n =
          +this.map.plan2 +
          +this.map.plan3 +
          +this.map.mrt2 +
          +this.map.mrt3 +
          +this.map.train2 +
          +this.map.train3 +
          +this.map.board2 +
          +this.map.board3;
        n += +this.map.other2 + +this.map.other3;
        n = Number(n);
      } catch {
        return "";
      }
      return n;
    },
    total3() {
      let n = 0;
      try {
        n =
          +this.map.plan4 +
          +this.map.plan5 +
          +this.map.mrt4 +
          +this.map.mrt5 +
          +this.map.train4 +
          +this.map.train5 +
          +this.map.board4 +
          +this.map.board5;
        n += +this.map.other4 + +this.map.other5;
        n = Number(n);
      } catch {
        return "";
      }
      return n;
    },
    total4() {
      let n = 0;
      try {
        n =
          +this.map.plan6 +
          +this.map.plan7 +
          +this.map.mrt6 +
          +this.map.mrt7 +
          +this.map.train6 +
          +this.map.train7 +
          +this.map.board6 +
          +this.map.board7;
        n += +this.map.other6 + +this.map.other7;
        n = Number(n);
      } catch {
        return "";
      }
      return n;
    },
    getW() {
      let n = this.total1 + this.total2 + this.total3 + this.total4;
      n = "" + n;
      n = [...n];
      if (n.length > 4) {
        n = n.slice(0, -4);
        return n.join("");
      }
      return "";
    },
    getG() {
      let n = this.total1 + this.total2 + this.total3 + this.total4;
      n = "" + n;
      n = [...n];

      if (n.length > 3) {
        n.reverse();
        return n[3];
      }
      return "";
    },
    get100() {
      let n = this.total1 + this.total2 + this.total3 + this.total4;
      n = "" + n;
      n = [...n];

      if (n.length > 2) {
        n.reverse();
        return n[2];
      }
      return "";
    },
    get10() {
      let n = this.total1 + this.total2 + this.total3 + this.total4;
      n = "" + n;
      n = [...n];

      if (n.length > 1) {
        n.reverse();
        return n[1];
      }
      return "";
    },
    get1() {
      let n = this.total1 + this.total2 + this.total3 + this.total4;
      n = "" + n;
      n = [...n];

      if (n.length > 0) {
        n.reverse();
        return n[0];
      }
      return "";
    },
  },
  components: {},

  methods: {},
  mounted() {
    localStorage.dtcPage4Print = "3";
  },
  beforeMount() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  --dtc-print-width: 1320px;
  position: relative;
  --dtc-border-color: var(--dark);
  margin: 0 auto;
  width: var(--dtc-print-width);
  margin-bottom: 60px;
  display: grid;
  border: 1px solid var(--dtc-border-color);
  border-radius: 5px;
  border-bottom: none;
}
.dx-main,
.dtc-title {
  input {
    border: none;
    border-bottom: 1px solid var(--dtc-border-color);
    border-radius: none;
    pointer-events: none;
  }
}
.dx-top-border {
  border-top: 1px solid var(--dtc-border-color);
}
.dtc-f2 {
  height: 66px;
  border-bottom: 1px solid var(--dtc-border-color);
  display: grid;
  place-items: center;
}

.dtc-f1 {
  height: 66px;
  border-bottom: 1px solid var(--dtc-border-color);
  display: grid;
  line-height: 46px;
  width: 100%;
  grid-gap: 12px;
  padding-top: 12px;
  grid-template-columns: max-content repeat(5, 80px max-content) 200px max-content;
}
.dtc-note2 {
  border-top: 1px solid var(--dtc-border-color);
  display: grid;
  grid-template-columns: 200px 1fr;
  > div:first-child {
    border-bottom: 1px solid var(--dtc-border-color);
  }
  .dx-note {
    border-bottom: 1px solid var(--dtc-border-color);
    border-left: 1px solid var(--dtc-border-color);
  }
}
.dtc-v10-grid {
  display: grid;
  grid-template-columns: 200px repeat(9, 1fr);
  grid-template-rows: repeat(5, 50px);
  text-align: center;
  > div:not(:first-child) {
    padding-top: 10px;
  }
  .dtc-top-border {
    border-top: 1px solid var(--dtc-border-color);
  }
  //border: 1px solid var(--dtc-border-color);
  > div {
    border-bottom: 1px solid var(--dtc-border-color);
    border-right: 1px solid var(--dtc-border-color);
  }
  > div:first-child {
    display: grid;
    grid-template-columns: 26px 1fr;
    .dtc-sec {
      display: grid;
      grid-template-areas: repeat(5, 1fr);
      > div {
        border: 1px solid var(--dtc-border-color);
        border-top: none;
        text-align: center;
        padding-top: 10px;
      }
    }
  }
}

.dtc-date2-grid {
  display: grid;
  grid-template-columns: 1fr 100px;
  text-align: center;
  border-top: 1px solid var(--dtc-border-color);
  .dtc-date4-grid {
    display: grid;
    grid-template-columns: 200px repeat(4, 248px);
    > div:not(:first-child) {
      padding-top: 10px;
    }
    > div {
      border-bottom: 1px solid var(--dtc-border-color);
      border-right: 1px solid var(--dtc-border-color);
      height: 44px;
      input {
        display: block;
        width: 100%;
        height: 100%;
        border: none;
        padding-left: 5px;
      }
      span {
        display: inline-block;
        padding-top: 5px;
      }
    }
  }
}
.dtc-word {
  //padding-right: 10px;
  transform: translateX(-10px);
  //border-bottom: 1px solid var(--dtc-border-color);
}
.dtc-title {
  justify-self: center;
  display: grid;
  grid-template-columns: 220px max-content 60px max-content 60px max-content;
  grid-gap: 12px;
  line-height: 34px;
}
.dtc-form4-input {
  display: grid;
  grid-template-columns: 1fr 340px 1fr;
  grid-template-rows: repeat(2, 38px);
  grid-gap: 3px;
  grid-auto-flow: dense;
}

@media screen {
  .dtc-main-section {
    padding-top: 12px;
  }
}

.input-group-prepend .input-group-text {
  min-width: 100px;
}

@media screen {
}

@media print {
  #app .input-group-prepend .input-group-text {
    background: white;
    color: black;
  }
  #app .input-group-append .input-group-text {
    background: white;
    color: black;
  }
  #app {
    .input-group input,
    .input-group-text input,
    input:focus,
    input,
    textarea,
    select,
    textarea:focus .input-group select {
      background: white;
      color: black;
    }
  }
}

@media print {
  @page {
    size: A4 landscape;
  }
  .dtc-main-section {
    --dtc-border-color: var(--dark);
  }
}
</style>
